module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","de"],"defaultLanguage":"en","siteUrl":"https://www.ontransition.com","redirect":true,"i18nextOptions":{"fallbackLng":"en","supportedLngs":["en","de"],"defaultNS":"common","interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-piwik-pro/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"3d49ed43-fdc7-4b2c-8b50-494ea1ad34bd","containerUrl":"https://ontransition.containers.piwik.pro","enabled":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
